import '../../App.css'

interface HeaderSectionProps {
    paragraphText: string;
}

function HeaderSection(props: HeaderSectionProps) {
    return (
        <div className='main-class flex items-end justify-center w-full'>
            <div
                className='text-center flex flex-col items-center justify-center gap-y-6 lg:gap-y-14 md:gap-y-6 sm:gap-y-6 lg:pb-12 md:pb-4 sm:pb-4 pb-4 w-full'>
                <p className="text-2xl lg:text-4xl md:text-2xl sm:text-2xl text-[#132B3D] font-bold ">
                    {props.paragraphText} </p>
                <h1 className="text-3xl lg:text-[95px] md:text-3xl sm:text-3xl text-[#132B3D] font-bold opacity-[0.03]">
                    A Boutique Software Firm</h1>
            </div>
        </div>
    );
}

export default HeaderSection;