import discovery from '../../Images/Group 448.png'
import product from '../../Images/Group 450.png'
import mvp from '../../Images/Group 453.png'
import MobileDevelopment from '../../Images/Group 449.png'
import webDevelopment from '../../Images/Group 451.png'
import Quality from '../../Images/Group 627.png'


function WhatWeDoPageSection() {
    return (
        <div className="flex justify-center items-center lg:px-36 px-8 md:px-8 sm:px-8 bg-[#FAFAFA]">
            <div className="flex flex-col gap-y-5 justify-center items-center lg:my-20 md:my-16 sm:my-16 my-16">
                <p className="text-4xl text-[#132B3D] font-bold ">What We Do?</p>
                <p className="text-[#808080] arial text-base text-center">Our services are defined by quality and
                    commitment</p>
                <div className="flex lg:mt-10 sm:mt-10 mt-10 md:mt-10 flex-wrap">
                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={discovery}></img>
                        <h2 className="text-[#132B3D] font-bold lg:text-xl text-sm sm:text-sm md:text-sm">
                            Discovery Workshops</h2>
                        <p
                            className="text-[#808080]  arial font-normal lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            We offer a comprehensive discovery workshop that helps us understand your needs and goals.
                        </p>
                    </div>

                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={product}></img>
                        <h2 className="text-[#132B3D] font-bold lg:text-xl text-sm sm:text-sm md:text-sm">
                            Product Design</h2>
                        <p
                            className="text-[#808080] arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            Our product design methodology uses the latest design philosophies, technologies, and best
                            practices to create intuitive, user-friendly and interactive designs that make your products
                            stand out.
                        </p>
                    </div>

                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={mvp}></img>
                        <h2 className="text-[#132B3D] font-bold  lg:text-xl text-sm sm:text-sm md:text-sm">MVP
                            Development</h2>
                        <p
                            className="text-[#808080] arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            Our MVP's are based on meticulous research and development which leads to a market viable
                            product, capable of encouraging early adopters.</p>
                    </div>

                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={MobileDevelopment}></img>
                        <h2 className="text-[#132B3D] font-bold lg:text-xl text-sm sm:text-sm md:text-sm">
                            Mobile Development</h2>
                        <p
                            className="text-[#808080] arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            We specialize in developing state-of-the-art mobile applications suited to your needs.</p>
                    </div>

                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={webDevelopment}></img>
                        <h2 className="text-[#132B3D] font-bold  lg:text-xl text-sm sm:text-sm md:text-sm">Web
                            Development</h2>
                        <p
                            className="text-[#808080] arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            We develop modern, user friendly design concepts built on latest & adaptive technologies
                            giving seamless integration across platforms, servers and devices.</p>
                    </div>

                    <div
                        className="flex flex-col gap-y-2 justify-top lg:items-center md:items-center sm:items-center items-center lg:w-1/3 w-full sm:w-full md:w-full border pt-10 pb-10">
                        <img alt="Images" src={Quality}></img>
                        <h2 className="text-[#132B3D] font-bold lg:text-xl text-sm sm:text-sm md:text-sm">
                            Quality Assurance</h2>
                        <p
                            className="text-[#808080] arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-sm text-xs sm:text-xs md:text-xs text-center md:text-center sm:text-center">
                            We practice multi-level quality assurance processes right from design level testing to code
                            level and UAT environment, delivering a prime time ready product, Every Time.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatWeDoPageSection;