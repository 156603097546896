import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import ShareYourIdeas from "../Components/GlobalComponent/ShareYourIdeaas";
import ProductList from "../Components/Products/ProductList";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const paragraphText: string = "Our Solutions & Collaborations!";
const footerText: string = "Let's Build Something Together";

function Products() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <HeaderSection paragraphText={paragraphText} />
      <ProductList />
      <ShareYourIdeas paragraphText={footerText} />
      <Footer />
    </div>
  );
}

export default Products;
