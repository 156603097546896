import ellipse from '../../Images/ellipse.svg'
import vector from '../../Images/Vector.png'
import { Link } from 'react-router-dom'

interface ShareYourIdeasProps {
    paragraphText: string;
}

function ShareYourIdeas(props: ShareYourIdeasProps) {
    return (
        <div
            className="flex lg:flex-row md:flex-col sm:flex-col flex-col justify-between items-center lg:py-12 sm:py-10 md:py-10 py-10 lg:px-32 gap-y-5 md:px-8 sm:px-8 px-8 bg-[#FAFAFA]">
            <p className="text-4xl text-[#132B3D] font-bold text-center">{props.paragraphText}</p>

            <div className="flex justify-center items-center">
                <div className="flex items-center">
                    <img src={ellipse} className="w-14 h-14" alt="Elipse"></img>
                    <Link to="/LetsBuild" className="p-1 text-[#132B3D] -translate-x-5 text-sm">Share Your Ideas With
                        Us</Link>
                    <img src={vector} className="-translate-x-3 w-2 h-4 arrow" alt="Vector"></img>
                </div>
            </div>
        </div>
    );
}

export default ShareYourIdeas;