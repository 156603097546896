import JobDetailsFirstSection from "../Components/JobsInnerPages/JobDetailsFirstSection";
import ApplyNowSection from "../Components/ApplyNow/ApplyNowSection";
import Footer from "../Components/GlobalComponent/Footer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ApiURL } from "../URL";
import axios from "axios";
import { useLocation } from "react-router-dom";

function ApplyNow() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname])

  const [detail, setDetail] = useState([]);
  let slug = useParams();
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${ApiURL}api/jobs/jobDetail/${slug.id}`)
        .then((response) => {
          setDetail(response.data.data[0]);
        });
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    console.log(detail, "On apply nowww");
  }, [detail]);

  return (
    <div className="h-screen flex flex-col">
      <JobDetailsFirstSection JobData={detail} />
      <ApplyNowSection JobData={detail} />
      <Footer />
    </div>
  );
}

export default ApplyNow;
