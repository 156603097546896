import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import ParagraphComponent from "../Components/WhatWeDo/ParagraphComponent";
import StayAheadWithTechnology from "../Components/WhatWeDo/StayAheadWithTechnology";
import ShareYourIdeas from "../Components/GlobalComponent/ShareYourIdeaas";
import WhatWeDoPageSection from "../Components/WhatWeDo/WhatWeDoPageSection";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const paragraphText: string = "Cost Conscious But Not Cheap!";
const shareideasText: string = "Let's Bring Your Ideas To Life!";

function WhatWeDoPage() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);
  
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <HeaderSection paragraphText={paragraphText} />
      <ParagraphComponent />
      <WhatWeDoPageSection />
      <StayAheadWithTechnology />
      <ShareYourIdeas paragraphText={shareideasText} />
      <Footer />
    </div>
  );
}

export default WhatWeDoPage;
