import ellipse from "../../Images/ellipse.svg";
import Vector from "../../Images/Vector.png";
import { Link } from "react-router-dom";

function FooterSection() {
  return (
    <div className="flex flex-col justify-center items-center lg:py-16 sm:py-10 md:py-10 py-10 lg:px-16 gap-y-5 md:px-8      sm:px-8 px-8">
      <p className="text-4xl text-[#132B3D] font-bold text-center">
        Be A Part Of Us!
      </p>
      <p className="text-[#808080] arial text-center lg:w-2/5 md:w-full sm:w-full w-full">
        Our network helps us to be who we are! Start your career with
        LathranSoft
      </p>

      <div className="flex justify-center items-center mt-6">
        <div className="flex items-center">
          <img src={ellipse} className="w-14 h-14" alt="Ellipse"></img>
          <Link to="/Career" className="p-1 text-[#132B3D] -translate-x-5">
            We Are Open
          </Link>
          <img
            src={Vector}
            className="-translate-x-3 w-2 h-4 arrow"
            alt="Vector"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default FooterSection;
