import Form from "../Components/LetsBuild/Form";
import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";

function LetsBuild() {
    return (
        <div className="h-screen flex flex-col  lg:pt-36 md:pt-20 sm:pt-20 pt-20">
            <Header />

            <div className="lg:h-screen md:h-auto sm:h-auto h-auto flex justify-center flex-col lg:px-48 px-8 md:px-8 sm:px-8 gap-x-10 lg:pb-20 md:pb-10 sm:pb-10 pb-10 pt-5 lg:gap-y-12 gap-y-8 md:gap-y-8 sm:gap-y-8">
                <div>
                    <h1 className="w-full flex justify-center items-center text-center text-2xl lg:text-4xl md:text-3xl sm:text-2xl text-[#132B3D] font-bold">
                        Let’s Build Something Together!</h1>
                </div>

                <div className="flex justify-center items-center">
                    <Form />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LetsBuild;