import Innovate from '../../Images/Group 6322.png'
import Team from '../../Images/Group 6466.png'
import Engineering from '../../Images/Group 6355.png'
import Celebrate from '../../Images/Group 6388.png'
import Fun from '../../Images/Group 6444.png'
import Sharing from "../../Images/Group 6455.png"

function LathranSecretFormula() {
    return (
        <div className="flex justify-center items-center px-8 lg:px-28 md:px-8 sm:px-8">
            <div className="flex flex-col gap-y-12 justify-center items-center my-20">
                <p className="text-4xl text-[#132B3D] font-bold text-center">LathranSoft's Secret Formula!</p>

                <div
                    className="flex justify-around flex-wrap gap-y-12 gap-x-3 lg:px-44 md:px-0 sm:px-0 px-0 lg:w-[1200px] w-full md:w-full sm:w-full">
                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Innovate} className="w-20 h-20" alt="Innovate"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">
                            Innovate or Die</h2>
                    </div>

                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Team} className="w-20 h-20" alt="Team"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">
                            Team or Nothing</h2>
                    </div>

                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Engineering} className="w-20 h-20" alt="Engineering"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">
                            Engineering Heart, Mind & Soul</h2>
                    </div>

                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Celebrate} className="w-20 h-20" alt="Celebrate"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">
                            Celebrate wins</h2>
                    </div>

                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Fun} className="w-20 h-20" alt="Fun"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">Fun
                            Workplace</h2>
                    </div>

                    <div className="flex flex-col gap-y-2  items-center basis-28 lg:basis-52 md:basis-28 sm:basis-28">
                        <img src={Sharing} className="w-20 h-20" alt="Sharing"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-lg text-sm sm:text-sm md:text-sm">
                            Sharing Knowledge</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LathranSecretFormula;