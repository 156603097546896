import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import Founders from "../Components/OurTeam/founders";
import TeamMembers from "../Components/OurTeam/TeamMembers";
import ComeJoinUs from "../Components/OurTeam/ComeJoinUs";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import NetworkOfIndustry from '../Components/HomePage/NetworkOfIndustry';
import StrategicPartners from "../Components/OurTeam/StreategicPartners";

const paragraphText: string = "Meet Our Team!";

function OurTeam() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <HeaderSection paragraphText={paragraphText} />
      <Founders />
      <NetworkOfIndustry />
      <StrategicPartners />
      {/* <TeamMembers /> */}
      <ComeJoinUs />
      <Footer />
    </div>
  );
}

export default OurTeam;
