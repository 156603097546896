import { PODDetialsArray } from "./PODDetailsData";

function HowWeDoItSection() {

    // console.log("PODDetialsArray",PODDetialsArray)

    const render = PODDetialsArray.map((item, index) =>
        <div key={index} className="relative p-5 flex flex-col gap-y-2 justify-top items-center border border-[#132B3D] rounded-3xl basis-72">
            <p className="arial text-[#132B3D] text-xs">
                {item.description}
            </p>
            <span className={`absolute -top-4 right-7 px-3 bg-[#FAFAFA] text-${item.colorCode} font-semibold text-base`}>{item.heading}</span>
        </div>
    );

    return (
        <div className="flex justify-center items-center lg:px-16 md:px-10 sm:px-10 px-10 bg-[#FAFAFA]">
            <div className="flex flex-col gap-y-2 justify-center items-center lg:my-24 md:my-16 sm:my-16 my-16">
                <p className="text-4xl text-[#132B3D] font-bold">How We Do It?</p>
                <p className="text-[#808080] arial text-base text-center">We are cross-functional and working collaboratively to meet customers need</p>
                <div className="flex justify-center lg:mt-10 sm:mt-10 mt-10 md:mt-10 flex-wrap lg:gap-y-16 md:gap-y-10 sm:gap-y-10 gap-y-10 gap-x-16 lg:w-[1000px] w-full md:w-full sm:w-full">
                    {render}
                </div>
            </div>
        </div>
    );
}

export default HowWeDoItSection;