import Clarity from '../../Images/Group 3966.png'
import Quest from '../../Images/Group 3955.png'
import cost from '../../Images/Group 3922.png'
import network from '../../Images/Group 3933.png'
import love from '../../Images/Group 3944.png'

function Team() {
    return (
        <div className="flex justify-center items-center lg:mt-10 md:mt-10 sm:mt-10 mt-10 lg:px-0 md:px-8 sm:px-8 px-8">
            <div className="flex flex-col gap-y-5 justify-center items-center">
                <div className="text-center lg:w-3/5 flex flex-col gap-y-5 md:w-full sm:w-full w-full">
                    <p className="mt-7 text-2xl italic ">We are a team of crazy engineers!
                        We accept all challenges to Innovate together as a Team with Engineering, Heart, Mind & Soul.
                        Our vision and mission are centered on providing ‘Customer Friendly as a Service’.</p>
                </div>
                <div
                    className="px-4 md:px-4 sm:px-4 lg:px-28 flex flex-wrap justify-center gap-y-5 sm:gap-y-5 md:gap-y-5 lg:gap-y-0 lg:flex-row sm:flex-wrap md:flex-wrap lg:my-10 my-6 md:my-6 sm:my-6 lg:gap-x-10 sm:gap-x-1 md:gap-x-1 gap-x-1 pb-8">
                    <div className="flex flex-col gap-y-3 justify-center items-center w-40">
                        <img alt="Images" src={Clarity} className="w-16 h-16"></img>
                        <h2 className="text-[#132B3D] font-bold text-center text-sm">Clarity of Purpose</h2>
                    </div>

                    <div className="flex flex-col gap-y-3 justify-center items-center w-40">
                        <img alt="Images" src={Quest} className="w-16 h-16"></img>
                        <h2 className="text-[#132B3D] font-bold text-center text-sm">Quest For Innovative Problem Solving
                        </h2>
                    </div>

                    <div className="flex flex-col gap-y-3 justify-center items-center w-40">
                        <img alt="Images" src={cost} className="w-16 h-16"></img>
                        <h2 className="text-[#132B3D] font-bold text-center text-sm">Cost Conscious But Not Cheap</h2>
                    </div>

                    <div className="flex flex-col gap-y-3 justify-center items-center w-40">
                        <img alt="Images" src={network} className="w-16 h-16"></img>
                        <h2 className="text-[#132B3D] font-bold text-center text-sm">Our Network of Partners and Experts
                        </h2>
                    </div>

                    <div className="flex flex-col gap-y-3 justify-center items-center w-40">
                        <img alt="Images" src={love} className="w-16 h-16"></img>
                        <h2 className="text-[#132B3D] font-bold text-center text-sm">Love For Music</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;