import './App.css';
import RouteComponent from './Components/Routing/RouteComponent';

function App() {
  return (
    <RouteComponent />
  );
}

export default App;
