import ellipse78 from '../../Images/Ellipse 78.png'
function ProudNumbers() {
    return (
        <div className="flex justify-center items-center lg:my-20 md:my-10 sm:my-10 my-10 lg:px-0 md:px-8 sm:px-8 px-8">
            <div className="flex flex-col gap-y-3 justify-center items-center">
                <div className="text-center lg:w-2/4 flex flex-col gap-y-2 md:w-full sm:w-full w-full">
                    <p className="text-4xl text-[#132B3D] font-bold">Proud Numbers!
                    </p>
                    <p className="text-[#808080] arial text-sm">We build digital products that people want and need. We make
                        the complex, simple and the simple compelling that connect with people on an emotional level,
                        and
                        the result is:</p>
                </div>
                <div
                    className="px-0 md:px-0 sm:px-0 lg:px-28 flex flex-wrap justify-center gap-y-5 sm:gap-y-5 md:gap-y-5 lg:gap-y-0 lg:flex-row sm:flex-wrap md:flex-wrap lg:mt-10 mt-10 md:mt-10 sm:mt-10 lg:gap-x-24 sm:gap-x-9 md:gap-x-9 gap-x-9">
                    <div className="flex flex-col justify-center items-center w-40 gap-y-4">
                        <div className="flex">
                            <img src={ellipse78} className="w-16 h-16" alt="Ellipse"></img>
                            <h1
                                className="lg:text-6xl md:text-5xl sm:text-5xl text-5xl font-bold z-10 custom-heading text-[#132B3D]">
                                02<span className="invisible">+</span></h1>
                        </div>
                        <h2 className="text-[#132B3D] arial text-center text-sm">Continents</h2>
                    </div>

                    <div className="flex flex-col justify-center items-center w-40 gap-y-4">
                        <div className="flex">
                            <img src={ellipse78} className="w-16 h-16" alt="Ellipse"></img>
                            <h1
                                className="lg:text-6xl md:text-5xl sm:text-5xl text-5xl font-bold z-10 custom-heading text-[#132B3D]">
                                16+</h1>
                        </div>
                        <h2 className="text-[#132B3D] arial text-center text-sm">Hours Timezone Coverage</h2>
                    </div>

                    <div className="flex flex-col justify-center items-center w-40 gap-y-4">
                        <div className="flex">
                            <img src={ellipse78} className="w-16 h-16" alt="Ellipse"></img>
                            <h1
                                className="lg:text-6xl md:text-5xl sm:text-5xl text-5xl font-bold z-10 custom-heading text-[#132B3D]">
                                35+</h1>
                        </div>
                        <h2 className="text-[#132B3D] arial text-center text-sm">Hybrid Teams</h2>
                    </div>

                    <div className="flex flex-col justify-center items-center w-40 gap-y-4">
                        <div className="flex">
                            <img src={ellipse78} className="w-16 h-16" alt="Ellipse"></img>
                            <h1
                                className="lg:text-6xl md:text-5xl sm:text-5xl text-5xl font-bold z-10 custom-heading text-[#132B3D]">
                                50+</h1>
                        </div>
                        <h2 className="text-[#132B3D] arial text-center text-sm">People and Counting</h2>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProudNumbers;