import JobDetailsFirstSection from "../Components/JobsInnerPages/JobDetailsFirstSection";
import JobDetailDescription from "../Components/JobsInnerPages/JobDetailDescription";
import ReleatedJobsOpenings from "../Components/JobsInnerPages/RelatedJobsOpenings";
import Footer from "../Components/GlobalComponent/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ApiURL } from "../URL";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { log } from "console";
 
function JobDetails() {
  const [detail, setDetail] = useState([]);
  let slug = useParams();
  console.log(slug);
  
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname])
  
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${ApiURL}api/jobs/jobDetail/${slug.slug}`)
        .then((response) => {
          setDetail(response.data.data[0]);
          console.log(detail, "beforee Detailsss");
        });
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    console.log(detail);
  }, [detail]);

  return (
    <div className="h-screen flex flex-col">
      {detail ? (
        <div>
          <JobDetailsFirstSection JobData={detail} />
          <JobDetailDescription JobData={detail} />
          <ReleatedJobsOpenings />
          <Footer />
        </div>
      ) : (
        <div>hello</div>
      )}
    </div>
  );
}

export default JobDetails;
