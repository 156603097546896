import ellipse from '../../Images/ellipse.svg'
import vector from "../../Images/Vector.png"
import { Link } from 'react-router-dom'

function ComeJoinUs() {
    return (
        <div
            className="bg-[#FAFAFA] flex flex-col justify-center items-center lg:py-16 sm:py-10 md:py-10 py-10 lg:px-16 gap-y-5 md:px-8      sm:px-8 px-8">
            <p className="text-4xl text-[#132B3D] font-bold text-center">Come Join Us!</p>
            <p className="text-[#808080] arial text-center lg:w-2/5 md:w-full sm:w-full w-full">We are always on the lookout
                for great talent
            </p>

            <div className="flex justify-center items-center lg:mt-10 md:mt-4 sm:mt-4 mt-4">
                <div className="flex items-center">
                    <img src={ellipse} className="w-14 h-14" alt="Ellipse"></img>
                    <Link to="/Career" className="p-1 text-[#132B3D] -translate-x-5">We Are Open</Link>
                    <img src={vector} className="-translate-x-3 w-2 h-4 arrow" alt="Vector"></img>
                </div>
            </div>
        </div>
    );
}

export default ComeJoinUs;