import HeadQuarters from '../../Images//Mask groupppp.png'
import Regional from '../../Images/Mask group (1).png'
import ourWorkshop from '../../Images/Mask group (2).png'

function Location() {
    return (
        <div className="md:w-full sm:w-full w-full lg:w-2/5  flex flex-col gap-y-5">
            <h1 className="text-[#132B3D] font-bold text-2xl">Locations</h1>
            <div className="flex gap-x-5">
                <img src={HeadQuarters} alt="HeadQuarterImage"></img>
                <div className="flex flex-col gap-y-1">
                    <p className="arial text-sm text-[#132B3D]">
                        Head Quarters
                    </p>
                    <p className="text-sm font-semibold text-[#132B3D]">
                        Columbus, Ohio, USA
                    </p>
                </div>
            </div>
            <div className="flex gap-x-5">
                <img src={Regional} alt="RegionalImage"></img>
                <div className="flex flex-col gap-y-1">
                    <p className="arial text-sm text-[#132B3D]">
                        Regional Office
                    </p>
                    <p className="text-sm font-semibold text-[#132B3D]">
                        Dubai, UAE
                    </p>
                </div>
            </div>
            <div className="flex gap-x-5">
                <img src={ourWorkshop} alt="OurWorkShop"></img>
                <div className="flex flex-col gap-y-1">
                    <p className="arial text-sm text-[#132B3D]">
                        Our Workshop
                    </p>
                    <p className="text-sm font-semibold text-[#132B3D]">
                        Karachi, Pakistan
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Location;