import "../../App.css";
import { Link } from "react-router-dom";
import sideArrow from "../../Images/Group 515.png";

interface JobDetailsFirstSectionProps {
  JobData: any;
}

function JobDetailsFirstSection(props: JobDetailsFirstSectionProps) {
  return (
    <div>
      <div>
        <header className="bg-[#FAFAFA80] flex  items-center justify-between py-5 px-9 fixed w-full z-[999] top-0 lg:backdrop-blur-sm md:backdrop-blur-sm sm:backdrop-blur-sm backdrop-blur-sm">
          <Link to="/Career" className="flex">
            <img src={sideArrow} alt="Arrow"></img>
            <p className="text-[#AB322F] font-semibold">Back To Career</p>
          </Link>

          <div
            className="lg:flex lg:items-center lg:w-auto hidden pt-6 lg:pt-0"
            id="nav-content"
          >
            <Link to="/LetsTalk">
              <p className="text-[#AB322F] hover:bg-[#f7d2d2] flex justify-center items-center border border-red-700 px-7 rounded-3xl h-11">
                Let's Talk
              </p>
            </Link>
          </div>
        </header>
      </div>

      <div className="main-class flex items-end justify-center w-full">
        <div className="text-center flex flex-col items-center justify-center gap-y-6 lg:gap-y-14 md:gap-y-6 sm:gap-y-6 lg:pb-12 md:pb-4 sm:pb-4 pb-4 w-full">
          <p className="text-2xl lg:text-4xl md:text-2xl sm:text-2xl text-[#132B3D] font-bold ">
            {props.JobData.job_title}{" "}
          </p>
          <div className="flex flex-col lg:flex-row md:flex-col flex-wrap sm:flex-col gap-x-20 justify-center items-center gap-y-5">
            <div className="flex lg:gap-x-20 md:gap-x-12 sm:gap-x-12 gap-x-12">
              <div className="flex flex-col">
                <span className="arial text-[#132B3D] text-sm font-medium">
                  Position Type
                </span>
                <span className=" text-[#132B3D] lg:text-lg font-semibold md:text-sm sm:text-sm text-sm">
                  {props.JobData.job_type}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="arial text-[#132B3D] text-sm font-medium">
                  Experience
                </span>
                <span className=" text-[#132B3D] lg:text-lg font-semibold md:text-sm sm:text-sm text-sm">
                  {props.JobData.experience_level}
                </span>
              </div>
            </div>
            <div className="flex lg:gap-x-20 md:gap-x-12 sm:gap-x-12 gap-x-12">
              <div className="flex flex-col">
                <span className="arial text-[#132B3D] text-sm font-medium">
                  Flexibility
                </span>
                <span className=" text-[#132B3D] lg:text-lg font-semibold md:text-sm sm:text-sm text-sm">
                  {props.JobData.work_type}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="arial text-[#132B3D] text-sm font-medium">
                  Location
                </span>
                <span className=" text-[#132B3D] lg:text-lg font-semibold md:text-sm sm:text-sm text-sm">
                  {props.JobData.country_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDetailsFirstSection;
