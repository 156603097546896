import { Link } from "react-router-dom";
import Arrow from "../../Images/Group 516.png";
import ellipse from "../../Images/ellipse.svg";
import Vector from "../../Images/Vector.png";
import { useEffect,useState } from "react";
import axios from "axios";
import { ApiURL } from "../../URL";

function ReleatedJobsOpenings() {
  const [JobsData, setJobs] = useState([]);

  useEffect(() => {
    //Get Jobs
    axios
      .get(`${ApiURL}api/jobs/getJobs`)
      .then((response) => {
        setJobs(response.data.data.sort(() => Math.random() - Math.random()).slice(0, 4));
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  }, []);
  const render = JobsData.map((item:any, index) => (
    <Link
      to={`/JobDetails/${item.job_slug}`}
      state={{ from: item }}
      key={index}
      className="lg:w-[45%] md:w-full sm:w-full w-full"
    >
      <div
        key={index}
        className=" flex flex-col gap-y-2 border-b-[1px] border-b-[#132B3D] p-2 hover:shadow-[5px_5px_1px_rgba(19,43,61,1)] ease-out transform transition duration-500"
      >
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-xl text-[#132B3D]">{item.job_title}</h1>
          <img src={Arrow} alt="Arrow"></img>
        </div>
        <p className="arial text-sm text-[#808080]">{item.job_desp}</p>
      </div>
    </Link>
  ));

  return (
    <div className="flex flex-col lg:mx-44 md:mx-10 sm:mx-10 mx-10 lg:py-24 gap-y-8 md:py-10 sm:py-10 py-10">
      <p className="text-2xl lg:text-4xl md:text-2xl sm:text-2xl text-[#132B3D] font-bold ">
        Related Job Openings
      </p>
      <div className="flex justify-between gap-x-10 flex-wrap lg:gap-y-14 md:gap-y-8 sm:gap-y-8 gap-y-8">
        {render}
      </div>
      <div className="flex justify-center items-center lg:mt-14 md:mt-4 sm:mt-4 mt-4">
        <div className="flex items-center ">
          <img src={ellipse} alt="ellipse" className="w-14 h-14"></img>
          <Link to="/Career" className="p-1 text-[#132B3D] -translate-x-5">
            View All Jobs
          </Link>
          <img
            src={Vector}
            className="-translate-x-3 w-2 h-4 arrow"
            alt="Vector"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default ReleatedJobsOpenings;
