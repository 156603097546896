import Contact from "../Components/LetsTalk/Contact";
import Location from "../Components/LetsTalk/Loction";
import WhatsOnYourMind from "../Components/LetsTalk/WhatsonyourMind";
import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";

function LetsTalk() {
    return (
        <div className="h-screen flex flex-col lg:pt-32 md:pt-20 sm:pt-20 pt-20">
            <Header />

            <div className="lg:h-screen md:h-auto sm:h-auto h-auto flex justify-center flex-col lg:px-40 px-8 md:px-8 sm:px-8 gap-x-10 lg:pb-20 md:pb-10 sm:pb-10 pb-10 pt-5 lg:gap-y-12 gap-y-8 md:gap-y-8 sm:gap-y-8">
                <div>
                    <h1 className="text-center flex justify-center items-center text-3xl lg:text-4xl md:text-3xl sm:text-3xl text-[#132B3D] font-bold">
                        Let's Grow Together!</h1>
                </div>

                <div className="flex flex-col lg:flex-row md:flex-row sm:flex-col lg:gap-y-0 gap-y-8 md:gap-y-8 sm:gap-y-8">
                    <Contact />
                    <Location />
                    <WhatsOnYourMind />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LetsTalk;