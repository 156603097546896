import Footer from '../Components/GlobalComponent/Footer';
import HeroSection from '../Components/HomePage/HeroSection';
import Header from '../Components/GlobalComponent/Header'
import NetworkOfIndustry from '../Components/HomePage/NetworkOfIndustry';
import WhatWeDo from '../Components/GlobalComponent/WhatWeDo';
import FooterSection from '../Components/GlobalComponent/FooterSection';
import StrategicPartners from '../Components/OurTeam/StreategicPartners';

function HomePage() {
    return (
        <div className="h-screen flex flex-col">
            <Header />
            <HeroSection />
            <NetworkOfIndustry />
            <StrategicPartners />
            <WhatWeDo />
            <FooterSection />
            <Footer />
        </div>
    );
}

export default HomePage;