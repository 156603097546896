import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import Job from "../Components/Career/Jobs";
import CareerHeaderSection from "../Components/Career/CareerHeaderSection";
import OurHiringProcess from "../Components/Career/OurHiringProcess";
import JoinOurTeam from "../Components/Career/JoinOutTeam";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Career() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);
  
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <CareerHeaderSection />
      {/* <Job /> */}
      <OurHiringProcess />
      <JoinOurTeam />
      {/* <Example/> */}
      <Footer />
    </div>
  );
}

export default Career;
