function ParagraphComponent() {
    return (
        <div
            className="flex flex-col gap-y-10 lg:flex-row md:flex-col sm:flex-col justify-between lg:my-20 lg:mx-36 md:my-16 md:mx-8 sm:my-16 sm:mx-8 my-16 mx-8">
            <div className="flex flex-col gap-y-2 lg:w-2/5 w-full md:w-full sm:w-full">
                <h1 className="text-[#132B3D]">Digital Transformation</h1>
                <h1 className="text-[#132B3D] font-bold text-xl">Engage Success With Right Technology!</h1>
                <p className="text-[#808080] arial">Success comes with the right technology roadmap! We’ll support you on
                    your path to the Digital
                    Enterprise. We offer expertise in the journey to the cloud, digital customer experience, market
                    optimization, business analytics, and data science. </p>
            </div>
            <div className="flex flex-col gap-y-2 lg:w-2/5 w-full md:w-full sm:w-full">
                <h1 className="text-[#132B3D]">On Demand Talent</h1>
                <h1 className="text-[#132B3D] font-bold text-xl">The Next Work Revolution!</h1>
                <p className="text-[#808080] arial">On demand staff augmentation helps you fast track your project delivery!
                    We can offer Staff
                    Augmentation (SA), Contract-to-hire (CTH) and Straight-Through-Hiring (STH) services for onshore,
                    off-shore, or hybrid services to help you succeed.</p>
            </div>
        </div>
    );
}

export default ParagraphComponent;