import PhoneNumber from '../../Images/Group 343.png';
import EmailAddress from '../../Images/Group 344.png'

function Contact() {
    return (
        <div className="md:w-full sm:w-full w-full lg:w-1/3 flex flex-col gap-y-5 ">
            <h1 className="text-[#132B3D] font-bold text-2xl">Contact</h1>
            <div className="flex gap-x-5">
                <img src={PhoneNumber} alt="PhoneNumber"></img>
                <div className="flex flex-col gap-y-1">
                    <p className="arial text-sm text-[#132B3D]">
                        Phone Number
                    </p>
                    <p className="text-sm font-semibold text-[#132B3D]">
                        +1 612 8508 005
                    </p>
                </div>
            </div>
            <div className="flex gap-x-5">
                <img src={EmailAddress} alt="EmailAddress"></img>
                <div className="flex flex-col gap-y-1">
                    <p className="arial text-sm text-[#132B3D]">
                        Email Address
                    </p>
                    {/* <p className="text-sm font-semibold text-[#132B3D]">
                        info@lathran.com
                    </p> */}
                    <a target="_blank" href="mailto: info@lathran.com" className="text-sm font-semibold text-[#132B3D]"> info@lathran.com</a>
                </div>
            </div>
        </div>
    );
}

export default Contact;