

function PrivacyPolicyText() {
    return (
        <div className="m-auto py-28 lg:w-2/3 md:w-4/5 sm:w-4/5 w-4/5">
            <p className="text-[#132B3D] text-xl leading-8">At LathranSoft, LLC we recognize the importance of protecting your personal information and are committed to processing it responsibly and in compliance with applicable data protection laws in all countries in which LathranSoft, LLC operates. This Privacy Statement describes LathranSoft, LLC's general privacy practices that apply to personal information we collect, use and share about consumers and other individuals within our clients, business partners, supplier and other organizations with which LathranSoft, LLC has or contemplates a business relationship. <br /><br />This Privacy Statement is supplemented by the LathranSoft, LLC Online Privacy Statement, which provides more information in the online context, including recruitment. We may provide additional or more specific information on the collection or use of personal information on websites or related to a specific product or service.</p>
            <br /><br />

            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">
                Why and how we collect and use your personal information?
            </h2>
            <p className="text-[#132B3D] text-xl leading-8">We may collect your personal information as an individual for various purposes, such as the following:</p>
            <br />

            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">
                Access and use of websites or other online services
            </h2>
            <p className="text-[#132B3D] text-xl leading-8">When entering one of our websites, or using an online service, we will record information necessary to provide you with access, for the operation of the website and for us to comply with security and legal requirements in relation to operating our site, such as passwords, IP address and browser settings.</p>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">We also collect information about your activities during your visit in order to personalize your website experience, such as recording your preferences and settings, and to collect statistics to help us improve and further develop our websites, products and services.</p>

            <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">
                Responding to your request for information, order, or support
            </h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">When you contact us (online or offline) in connection with a request for information, to order a product or service, to provide you with support, or to participate in a forum or other social computing tool, we collect information necessary to fulfill your request, to grant you access to the product or service, to provide you with support and to be able to contact you. For instance, we collect your name and contact information, details about your request and your agreement with us and the fulfillment, delivery and invoicing of your order and we may include client satisfaction survey information.</p>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">We retain such information for administrative purposes, defending our rights, and in connection with our relationship with you.</p>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">When you provide your name and contact information to register in connection with such a request, the registration may serve to identify you when you visit our websites. For ordering of most services and products we require you to have registered an LathranSoft, LLCid. Registration may also allow you to customize and control your privacy settings.</p>

            <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">
                Your use of LathranSoft, LLC Cloud services
            </h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">We collect information about your use of LathranSoft, LLC Cloud services to enable product features to operate, improve your user experience, tailor our interactions with you, inform our clients on the overall use of the services, provide support and improve and develop our products and services. For details regarding the technologies we employ, the personal information we collect, as well as how to control or block tracking or to delete cookies, please refer to the LathranSoft, LLC Online Privacy Statement.</p>
            <br />

            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">Contacting employees of our clients, prospects, partners and suppliers</h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">In our relationship with clients or prospects, partners and suppliers, they also provide us with business contact information (such as name, business contact details, position or title of their employees, contractors, advisors and authorized users) for purposes such as contract management, fulfillment, delivery of products and services, provision of support, invoicing and management of the services or the relationship.</p>
            <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">Marketing</h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">Most information we collect about you comes from our direct interactions with you. When you register for an event we may collect information (online or offline) in relation to the event organization, and during an event, such as participation in sessions and survey results. We combine the personal information we collect to develop aggregate analysis and business intelligence for conducting our business and for marketing purposes. You can choose to receive information by email, telephone or postal mail about our products and services, or sign-up for subscriptions. When visiting our websites or using our services we may provide you with personalized information.  Where we reference that we use your personal information in connection with a request, order, transaction or agreement (or preparing for the same), or to provide you with services that you requested (such as a website), we do this because it is necessary for the performance of an agreement with you. Where we reference that we use your personal information in relation to marketing, improvement or development of our products or services, for reasons of safety and security, or regulatory requirements other than in connection with your agreement or request, we do this on the basis of our or a third party’s legitimate interests, or with your consent. When we collect and use your personal information subject to the EU Privacy Legislation this may have consequences for Your Rights.</p>
            <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">Sharing of Personal Information</h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">As a global organization offering a wide range of products and services, with business processes, management structures and technical systems that cross borders, LathranSoft, LLC has implemented global policies, along with standards and procedures, for consistent protection of personal information. As a global company, we may share information about you with our subsidiaries world-wide and transfer it to countries in the world where we do business in accordance with this Privacy Statement.</p>
            <br /> <br />
            <p className="text-[#132B3D] text-xl leading-8">LathranSoft, LLC provides your personal data to third parties such as:<br /> <br />

                Bank affiliates to conduct due diligence <br /> <br />

                Vendors to provide us with services (i.e. payment card processing, reimbursement or payment, direct deposit, travel, 1099 services) <br /> <br />

                Competent authorities, courts and bodies in response to a court order, summons or subpoena, regulatory requests, or as permitted or required by law when we reasonably believe it is necessary or appropriate to investigate, prevent or take action against illegal activities, suspected fraud, or situations involving potential threats to the physical safety of any person <br /> <br />

                Any third party that acquires, or is interested in acquiring, all or part of our assets or shares, or that succeeds us in carrying on all or part of our business. <br /> <br />

                Between LathranSoft, LLC controlled subsidiaries we only grant access to personal information on a need-to-know basis, necessary for the purposes for which such access is granted. In some cases, LathranSoft, LLC uses suppliers located in various countries to collect, use, analyze, and otherwise process personal information on its behalf. Where appropriate, LathranSoft, LLC may also share your personal information with selected partners to help us provide you, or the company you work for, products or services, or to fulfill your requests, or with your consent. When selecting our suppliers and partners, we take into account their data handling processes. If LathranSoft, LLC decides to sell, buy, merge or otherwise reorganize businesses in some countries, such a transaction may involve the disclosure of personal information to prospective or actual purchasers, or the receipt of such information from sellers. It is LathranSoft, LLC's practice to require appropriate protection for personal information in these types of transactions. Please be aware that in certain circumstances, personal information may be subject to disclosure to government agencies pursuant to judicial proceeding, court order, or legal process. We may also share your personal information to protect the rights or property of LathranSoft, LLC, our business partners, suppliers or clients, and others when we have reasonable grounds to believe that such rights or property have been or could be affected. </p> <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">Information Security and Accuracy</h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">We intend to protect your personal information and to maintain its accuracy. LathranSoft, LLC implements reasonable physical, administrative and technical safeguards to help us protect your personal information from unauthorized access, use and disclosure. For example, we encrypt certain sensitive personal information such as credit card information when we transmit such information over the Internet. We also require that our suppliers protect such information from unauthorized access, use and disclosure.</p>
            <br />
            <h2 className="text-[#132B3D] text-xl leading-8 font-semibold">Retention Period</h2>
            <br />
            <p className="text-[#132B3D] text-xl leading-8">We will not retain personal information longer than necessary to fulfill the purposes for which it is processed, including the security of our processing complying with legal and regulatory obligations (e.g. audit, accounting and statutory retention terms), handling disputes, and for the establishment, exercise or defense of legal claims in the countries where we do business. Because the circumstances may vary depending on the context and the services, the information provided in Online Privacy Statement or provided in a specific notice may provide more detailed information on applicable retention terms.</p>
        </div>
    );
}

export default PrivacyPolicyText;