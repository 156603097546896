import { Link } from 'react-router-dom'
import '../../App.css'

function CareerHeaderSection() {

    const ScrolltoJobOpening = () => {
        window.scrollTo({
            top: 350,
            behavior: "smooth",
        });
    }

    return (
        <div className='main-class flex items-center justify-center w-full '>
            <div
                className='text-center flex flex-col items-center justify-center gap-y-4 lg:gap-y-8 md:gap-y-4 sm:gap-y-4 pb-5 w-full pt-10'>
                <p className="text-2xl lg:text-4xl md:text-2xl sm:text-2xl text-[#132B3D] font-bold w-full">
                    Find Your Dream Opportunity!</p>
                {/* <div className="flex gap-x-9 mt-3"> */}
                {/* <button
                        className="flex justify-center items-center lg:w-44 md:w-32 sm:w-32 w-32 rounded-full lg:h-14 md:h-10 sm:h-10 h-10 bg-[#132B3D] text-white lg:text-base md:text-sm sm:text-sm text-sm"
                        onClick={ScrolltoJobOpening}>
                        Open Rules</button> */}
                {/* <form action="mailto:admin@lathran.com">
                        <button className="flex justify-center items-center bg-[#AB322F] lg:w-44 md:w-32 sm:w-32 w-32 rounded-full lg:h-14 md:h-10 sm:h-10 h-10 text-white">Upload Resume</button>
                    </form> */}
                {/* <Link to="/RandomCv" className="flex justify-center items-center bg-[#AB322F] lg:w-44 md:w-32 sm:w-32 w-32 rounded-full lg:h-14 md:h-10 sm:h-10 h-10 text-white lg:text-base md:text-sm sm:text-sm text-sm">Upload Resume</Link> */}
                {/* <a target="_blank" href="mailto:admin@lathran.com" className="flex justify-center items-center bg-[#AB322F] lg:w-44 md:w-32 sm:w-32 w-32 rounded-full lg:h-14 md:h-10 sm:h-10 h-10 text-white lg:text-base md:text-sm sm:text-sm text-sm">Upload Resume</a> */}
                {/* </div> */}
            </div>
        </div>
    );
}

export default CareerHeaderSection;