import { Routes, Route } from "react-router-dom";
import HomePage from "../../Pages/HomePage";
import WhatWeDoPage from "../../Pages/WhatWeDoPage";
import WhoWeAre from "../../Pages/WhoWeAre";
import OurTeam from "../../Pages/OurTeam";
import POD from "../../Pages/POD";
import Products from "../../Pages/Products";
import LetsTalk from "../../Pages/LetsTalk";
import LetsBuild from "../../Pages/LetsBuild";
import Career from "../../Pages/Career";
// import JobDetails from '../../Pages/JobDetails';
// import JobDetails from '../../Pages/JobDetails';
import JobDetails from "../../Pages/JobDetails";
import ApplyNow from "../../Pages/ApplyNow";
import ProductDetailsPaqe from "../Products/ProductDetailsPage";
import ProductDetailsPageMain from "../../Pages/ProductDetailsPageMain";
import RandomCv from "../../Pages/RandomCv";
import PrivacyPolicy from "../../Pages/PrivacyPolicy";

const RouteComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/WhoWeAre" element={<WhoWeAre />} />
        <Route path="/WhatWeDo" element={<WhatWeDoPage />} />
        <Route path="/OurTeam" element={<OurTeam />} />
        <Route path="/POD" element={<POD />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/LetsTalk" element={<LetsTalk />} />
        <Route path="/LetsBuild" element={<LetsBuild />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/JobDetails/:slug" element={<JobDetails />} />
        <Route path="/ApplyNow/:id" element={<ApplyNow />} />
        <Route path="/Product/:id" element={<ProductDetailsPageMain />} />
        <Route path="/RandomCv" element={<RandomCv />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
};

export default RouteComponent;
