import tracy from '../../Images/image 35.png'
import rachel from '../../Images/image 35 (1).png'
import keith from '../../Images/image 35 (2).png'
import steve from '../../Images/image 35 (3).png'
import alfonso from '../../Images/image 35 (4).png'
import imran from '../../Images/imran.png'
import chris from '../../Images/chris.png'
import phabhakara from '../../Images/prabhakara.png'
import thor from '../../Images/tHOR.png'
import ellipse from '../../Images/ellipse.svg'
import vector from '../../Images/Vector.png'
import { Link } from 'react-router-dom'

function NetworkOfIndustry() {
    const dataArray = [
        {
            imgSrc: tracy,
            name: 'TRACY A.',
            role: 'Advanced Engineering Advisor'
        },
        {
            imgSrc: rachel,
            name: 'RACHEL.W',
            role: 'Retail Transformation Advisor'
        },
        {
            imgSrc: keith,
            name: 'KEITH H.',
            role: 'Marketing and Creative Advisor'
        },
        {
            imgSrc: steve,
            name: 'STEVE L.',
            role: 'Technology Advisor'
        },
        {
            imgSrc: alfonso,
            name: 'ALFONSO S.',
            role: 'Digital Strategist and Author'
        },
        {
            imgSrc: imran,
            name: 'IMRAN S.',
            role: 'Innovative Technology Consultant'
        },
        {
            imgSrc: chris,
            name: 'CHIRS C.',
            role: 'Strategic Cybersecurity Leader'
        },
        {
            imgSrc: phabhakara,
            name: 'PRABHAKARA C.',
            role: 'Cloud Solutions Visionary'
        },
        {
            imgSrc: thor,
            name: 'THORKILD (THOR)',
            role: 'Head of IT'
        }
    ];

    // const render = dataArray.map((item, index) =>
    //     <div className="flex flex-col gap-y-2 justify-center items-center">
    //         <img src={item.imgSrc} className="lg:w-36 lg:h-36 md:w-36 md:h-36 sm:w-20 sm:h-20 w-20 h-20" alt="TracyImage"></img>
    //         <h2 className="text-[#132B3D] font-bold text-center">{item.name}</h2>
    //         <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">{item.role}</p>
    //     </div>
    // );

    // Mapping dataArray to JSX elements
    const render = dataArray.map((item, index) => (
        <div key={index} className="flex flex-col gap-y-2 justify-start items-center w-[30%] sm:w-[40%] md:w-[15%] lg:w-[15%]">
            <img src={item.imgSrc} className="lg:w-36 lg:h-36 md:w-36 md:h-36 sm:w-20 sm:h-20 w-20 h-20" alt="TracyImage" />
            <h2 className="text-[#132B3D] font-bold text-center">{item.name}</h2>
            <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">{item.role}</p>
        </div>
    ));

    return (
        <div className="flex justify-center items-center lg:py-20 lg:px-32 md:py-10 sm:py-10 py-10 md:px-8 sm:px-8 px-8 bg-[#FAFAFA]">
            <div className="flex flex-col gap-y-5 justify-center items-center">
                <p className="text-4xl text-[#132B3D] font-bold text-center">Meet Our Experts!</p>
                <p className="text-[#808080] arial text-base text-center">Our community of tech wizards, guiding us in
                    changing the
                    future</p>
                <div className="flex flex-wrap justify-center gap-y-5 sm:gap-y-5 md:gap-y-8 lg:gap-y-8 lg:flex-row sm:flex-wrap md:flex-wrap my-10 lg:gap-x-10 sm:gap-x-14 md:gap-x-14 gap-x-14">
                    {render}
                    {/* <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={tracy} className="lg:w-36 lg:h-36 md:w-36 md:h-36 sm:w-20 sm:h-20 w-20 h-20" alt="TracyImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">TRACY A.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Advanced
                            Engineering Advisor</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={rachel} className="w-36 h-36" alt="RachelImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">RACHEL.W</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Retail
                            Transformation Advisor</p>
                    </div> */}

                    {/* <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={keith} className="w-36 h-36" alt="KeithImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">KEITH H.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Marketing
                            and Creative Advisor</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={steve} className="w-36 h-36" alt="SteveImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">STEVE L.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">
                            Technology Advisor</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={alfonso} className="w-36 h-36" alt="AlfonsoImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">ALFONSO S.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Digital
                            Strategist and Author</p>
                    </div>
                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={imran} className="w-36 h-36" alt="AlfonsoImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">IMRAN S.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Innovative Technology Consultant</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={chris} className="w-36 h-36" alt="AlfonsoImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">CHIRS C.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Strategic Cybersecurity Leader</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={phabhakara} className="w-36 h-36" alt="AlfonsoImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">PRABHAKARA C.</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Cloud Solutions Visionary</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center">
                        <img src={thor} className="w-36 h-36" alt="AlfonsoImage"></img>
                        <h2 className="text-[#132B3D] font-bold text-center">THORKILD (THOR)</h2>
                        <p className="text-[#132B3D] text-center arial text-xs lg:w-[165px] w-32 sm:w-32 md:w-32">Head of IT</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default NetworkOfIndustry;