import ellipse from "../../Images/ellipse.svg";
import "../../App.css";
import Vector from "../../Images/Vector.png";
import { Link } from "react-router-dom";

function HeroSection() {
  const setHeight = () => {
    // document.getElementById("my-element").style.minHeight =
    //   window.innerHeight + "px";
    //   document.getElementsByClassName("image-background")[0].
  };

  // define mobile screen size:

  let deviceWidth = window.matchMedia("(max-width: 1024px)");

  if (deviceWidth.matches) {
    // set an event listener that detects when innerHeight changes:

    window.addEventListener("resize", setHeight);

    // call the function once to set initial height:

    setHeight();
  }
  return (
    <div className="image-background flex flex-col justify-between items-center">
      <div className=" text-center w-11/12 lg:w-2/4 md:w-11/12 sm:w-11/12 flex flex-col gap-y-4 justify-center h-full">
        <h1 className="text-lg text-[#132B3D]">A Boutique Software Firm</h1>
        <p className="text-4xl lg:text-5xl md:text-4xl sm:text-4xl text-[#132B3D] font-bold lg:leading-tight md:leading-tight sm:leading-tight leading-tight">
          Customer Friendly As A Service
        </p>

        <div className="flex justify-center items-center lg:mt-14 md:mt-4 sm:mt-4 mt-4">
          <div className="flex items-center ">
            <img src={ellipse} alt="ellipse" className="w-14 h-14"></img>
            <Link to="/LetsBuild" className="p-1 text-[#132B3D] -translate-x-5">
              Let's Build Something Together
            </Link>
            <img
              src={Vector}
              className="-translate-x-3 w-2 h-4 arrow"
              alt="Vector"
            ></img>
          </div>
        </div>
      </div>

      {/* <!-- Buttons --> */}
      <div className="flex w-full text-center h-16">
        <div className="flex justify-center items-center bg-[#89AD3D] h-full w-full text-white font-normal lg:font-semibold sm:font-xs md:font-xs lg:text-2xl text-xs sm:text-sm md:text-sm ">
          Innovate Or Die
        </div>
        <div className="flex justify-center items-center bg-[#ED8425] h-full w-full text-white font-normal lg:font-semibold sm:font-xs md:font-xs lg:text-2xl text-xs sm:text-sm md:text-sm ">
          Team Or Nothing
        </div>
        <div className="flex justify-center items-center bg-[#AB322F] h-full w-full text-white font-normal lg:font-semibold sm:font-xs md:font-xs lg:text-2xl text-xs sm:text-sm md:text-sm ">
          Engineering Heart, Mind & Soul
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
