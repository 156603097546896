import ImageFounder1 from "../../Images/image 35555.png";
import ImageFounder2 from "../../Images/tanveer2.png";
import ImageFounder3 from "../../Images/image45.png";
import ImageFounder4 from "../../Images/Group 710.png";

function Founders() {
  return (
    <div className="flex justify-center items-center lg:px-24 md:px-8 sm:px-8 px-8 w-full">
      <div className="flex flex-col gap-y-5 justify-center items-center lg:py-20 md:py-10 sm:py-10 py-10 w-full">
        <p className="text-4xl text-[#132B3D] font-bold text-center">
          Our Leadership
        </p>
        <p className="text-[#808080] arial text-center w-full mb-5">
          Reach out to our leaders to explore business solutions.
        </p>
        <div className="flex flex-wrap justify-center items-center gap-y-12 lg:gap-x-12 md:gap-x-10 sm:gap-x-10 gap-x-10 w-full">
          <div className="flex flex-col jus items-center sm:w-[40%] w-[40%] lg:w-[22%] md:w-[22%]">
            <img
              src={ImageFounder1}
              className="mb-5 lg:w-44 lg:h-44 w-20 h-20 md:w-32 md:h-32 sm:h-32 sm:w-32"
              alt="ImageFounder1"
            ></img>
            <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
              Sharjeel Sajid
            </h2>
            <p className="text-[#132B3D] text-center arial font-normal  text-xs">
              Founder & Chief Architect
            </p>
          </div>

          <div className="flex flex-col justify-center items-center sm:w-[40%] w-[40%] lg:w-[22%] md:w-[20%]">
            <img
              src={ImageFounder2}
              className="mb-5 lg:w-44 lg:h-44 w-20 h-20 md:w-32 md:h-32 sm:h-32 sm:w-32"
              alt="ImageFounder"
            ></img>
            <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
              Dr. Tanveer Q.
            </h2>
            <p className="text-[#132B3D] text-center arial   text-xs ">
              Co-Founder & CEO
            </p>
          </div>

          {/* <div className="flex flex-col justify-center items-center sm:w-[40%] w-[40%] lg:w-[22%] md:w-[20%]">
                        <img src={ImageFounder3}
                            className="mb-5 lg:w-44 lg:h-44 w-20 h-20 md:w-32 md:h-32 sm:h-32 sm:w-32" alt="ImageFounder"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
                            Naufil Khan</h2>
                        <p className="text-[#132B3D] text-center arial text-xs ">
                            Chief Technology Officer                        </p>
                    </div> */}

          <div className="flex flex-col justify-center items-center sm:w-[40%] w-[40%] lg:w-[22%] md:w-[20%]">
            <img
              src={ImageFounder4}
              className="mb-5 lg:w-44 lg:h-44 w-20 h-20 md:w-32 md:h-32 sm:h-32 sm:w-32"
              alt="ImageFounder"
            ></img>
            <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
              Muhammad Rasheed
            </h2>
            <p className="text-[#132B3D] text-center arial text-xs ">
              Software Architect
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Founders;
