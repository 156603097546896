import discovery from '../../Images/Group 448.png'
import product from '../../Images/Group 450.png'
import mvp from '../../Images/Group 453.png'
import MobileDevelopment from '../../Images/Group 449.png'
import webDevelopment from '../../Images/Group 451.png'
import Quality from '../../Images/Group 627.png'

function WhatWeDo() {
    return (
        <div className="flex justify-center items-center lg:px-36 bg-[#FAFAFA] md:px-8 sm:px-8 px-8">
            <div className="flex flex-col gap-y-5 justify-center items-center lg:my-24 md:my-14 sm:my-14 my-14">
                <p className="text-4xl text-[#132B3D] font-bold text-center">What We Do?</p>
                <p className="text-[#808080] arial text-base text-center">Our services are defined by quality and
                    commitment</p>

                <div className="flex lg:mt-10 sm:mt-10 mt-10 md:mt-10 flex-wrap gap-y-12">
                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={discovery} className="w-20 h-20" alt="Discovery"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">
                            Discovery Workshops</h2>
                        <p
                            className="text-[#808080] text-center arial font-normal lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            Better products come from discovery
                            workshops!</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={product} className="w-20 h-20" alt="Product"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">
                            Product Design</h2>
                        <p
                            className="text-[#808080] text-center arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            A successful product is one that users love!
                        </p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={mvp} className="w-20 h-20" alt="MVP"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">MVP
                            Development</h2>
                        <p
                            className="text-[#808080] text-center arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            The product success starts with an MVP!</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={MobileDevelopment} className="w-20 h-20" alt="MobileDevelopment"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">
                            Mobile Development</h2>
                        <p
                            className="text-[#808080] text-center arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            Build your competitive advantage through mobile
                            solutions!</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={webDevelopment} className="w-20 h-20" alt="WebDevelopment"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">Web
                            Development</h2>
                        <p
                            className="text-[#808080] text-center arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            Helping you build what your customers will
                            love!</p>
                    </div>

                    <div className="flex flex-col gap-y-2 justify-center items-center lg:w-1/3 w-full sm:w-full md:w-1/2">
                        <img src={Quality} className="w-20 h-20" alt="Quality"></img>
                        <h2 className="text-[#132B3D] font-bold text-center lg:text-base text-sm sm:text-sm md:text-sm">
                            Quality Assurance</h2>
                        <p
                            className="text-[#808080] text-center arial lg:w-72 w-36 sm:w-36 md:w-36 lg:text-base text-xs sm:text-xs md:text-xs">
                            Developing an idea is more than just creating,
                            coding and launching!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatWeDo;