import ellipse from '../../Images/ellipse.svg'
import vector from '../../Images/Vector.png'

function JoinOurTeam() {
    return (
        <div
            className="flex flex-col justify-center items-center lg:py-16 sm:py-10 md:py-10 py-10 lg:px-16  md:px-8 sm:px-8 px-8 gap-y-6 bg-[#FAFAFA]">
            <p className="text-4xl text-[#132B3D] font-bold text-center">Join Our Team!</p>
            <p className="text-[#808080] arial text-center lg:w-2/5 md:w-full sm:w-full w-full">If you are interested in
                joining our team, but your perfect
                position is not available, please share your resume directly with us</p>
            <div className="flex justify-center items-center">
                <div className="flex items-center">
                    <img src={ellipse} className="w-14 h-14" alt="Ellipse"></img>
                    {/* <form action="mailto:admin@lathran.com">
                        <button className="p-1 text-[#132B3D] -translate-x-5">Email Your Resume</button>
                    </form> */}
                    <a target="_blank" href="mailto:admin@lathran.com" className="p-1 text-[#132B3D] -translate-x-5">Email Your Resume</a>
                    <img src={vector} className="-translate-x-3 w-2 h-4 arrow" alt="Vector"></img>
                </div>
            </div>
        </div>
    );
}

export default JoinOurTeam;