import product from '../../Images/Group 694.png'
import shape from '../../Images/Group 693.png'
import cheer from '../../Images/Group 692.png'
import think from '../../Images/Group 691.png'
import live from '../../Images/Group 689.png'
import broaden from '../../Images/Group 690.png'

function SimplifyComplexity() {
    return (

        <div
            className="flex flex-col justify-center items-center lg:py-32 gap-y-4 md:py-14 sm:py-14 py-14 lg:px-0 md:px-8 sm:px-8 px-8">
            <p className="text-4xl text-[#132B3D] font-bold text-center">Simplify Complexity!</p>
            <p className="text-[#808080] arial text-sm text-center">Our business values and practices are based on ethical
                principles that guide us through our operations</p>

            <div className="flex flex-wrap gap-x-4 justify-center mt-5 gap-y-8 lg:gap-y-0 md:gap-y-8 sm:gap-y-8 w-11/12">

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={product} alt="Product"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial text-sm font-semibold ">Product Success <br></br> Comes With Priority
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={shape} alt="Shape"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial text-sm font-semibold ">Shape Product <br></br> Together
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={cheer} alt="Cheer"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial text-sm font-semibold ">Cheer For Our <br></br> Team
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={think} alt="Thinkk"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial  text-sm font-semibold">Think Like A <br></br>User
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={live} alt="Livee"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial  text-sm font-semibold">Live With Test-Learn Approach
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-center items-center basis-44 lg:gap-y-6 md:gap-y-2 sm:gap-y-2 gap-y-2">
                    <img src={broaden} alt="Broaden"></img>
                    <div className="bg-[#F3F4F5] rounded-3xl text-center w-full h-16 flex justify-center items-center">
                        <p className="text-[#132B3D] arial text-sm font-semibold">Broaden The <br></br> Mandate
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimplifyComplexity;