export const PODDetialsArray: {colorCode:string,  heading: string; description: string }[] = [
    { colorCode:"[#ED8425]", heading: "Scrum Masters", description: "Keep the team organized, on track, and focused on what matters." },
    { colorCode:"[#AB322F]", heading: "Product Owners", description: "A team captain accountable for maximizing the value of the results produced by the team." },
    { colorCode:"[#89AD3D]", heading: "Business Analysts", description: "Work closely with stakeholders by capturing business process needs, translating them, revalidating them, and then passing the requirements to in-house technical experts." },
    { colorCode:"[#AB322F]", heading: "System Architects", description: "Capable of developing strategic system architecture plans with business and technology proficiency." },
    { colorCode:"[#89AD3D]", heading: "UX/UI Engineers", description: "Develop concepts with good user experience for understanding what users will experience with finish product." },
    { colorCode:"[#ED8425]", heading: "Full Stack Engineers", description: "Experienced engineers help us to design, test, and implement highest quality products, which are close view of the final product of our valued customers." },
    { colorCode:"[#89AD3D]", heading: "Quality Assurance Engineers", description: "QA automation techniques used by our engineers verify that the product aligns technical requirements with business goals. They take ownership of driving quality throughout the development phase.r" },
    { colorCode:"[#ED8425]", heading: "DevOps Engineers", description: "We have experts in analyzing, designing, and implementing the increment to any product. They drive continuous delivery while ensuring high availability and scalability on production system." },
    { colorCode:"[#AB322F]", heading: "Support Engineerss", description: "Equipped with technical knowledge for customer interaction, providing resolution and escalation and constantly monitoring the health of the system." },
];