import image1 from '../../Images/image 39.png';
import image2 from '../../Images/image 23.png';
import image3 from '../../Images/image 7.png';
import image4 from '../../Images/image 9.png';
import image5 from '../../Images/image 11.png';
import image6 from '../../Images/image 37.png';
import image7 from '../../Images/image 12.png';
import image8 from '../../Images/image 13.png';
import image9 from '../../Images/image 22.png';
import image10 from '../../Images/image 14.png';
import image11 from '../../Images/image 19.png';
import image12 from '../../Images/image 38.png';
import image13 from '../../Images/image 16.png';
import image14 from '../../Images/image 17.png';
import image15 from '../../Images/image 20.png';
import image16 from '../../Images/image 21.png';
import image17 from '../../Images/image 40.png';
import image18 from '../../Images/image 5.png';
import image19 from '../../Images/image 36.png';
import image20 from '../../Images/image 25.png';
import image21 from '../../Images/image 26.png';
import image22 from '../../Images/image 27.png';

function StayAheadWithTechnology() {
    return (
        <div className="flex justify-center items-center lg:px-16 md:px-8 sm:px-8 px-8">
            <div
                className="flex flex-col lg:gap-y-5 justify-center items-center lg:my-24 md:my-16 sm:my-16 my-16 md:gap-y-3 sm:gap-y-3 gap-y-3">
                <p className="text-[#132B3D] font-bold text-4xl text-center">
                    Stay Ahead With Technology!</p>
                <p className="arial text-[#808080] text-sm lg:w-2/5 text-center">
                    In a fast-changing and highly competitive industry, you need the best technologies and best tools at
                    your disposal
                </p>

                <div
                    className="flex justify-center lg:mt-10 md:mt-4 sm:mt-4 mt-4  flex-wrap gap-x-8 lg:gap-x-24 md:gap-x-8 sm:gap-x-8 gap-y-4 lg:gap-y-0 sm:gap-y-4 md:gap-y-4">
                    <div>
                        <img alt="Images" src={image1}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image2}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image3}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image4}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image5}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image6}></img>
                    </div>

                </div>

                <div
                    className="flex justify-center lg:mt-10 md:mt-4 sm:mt-4 mt-4 flex-wrap gap-x-8 lg:gap-x-24 md:gap-x-8 sm:gap-x-8 gap-y-4 lg:gap-y-0 sm:gap-y-4 md:gap-y-4">
                    <div>
                        <img alt="Images" src={image7}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image8}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image9}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image10}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image11}></img>
                    </div>

                </div>

                <div
                    className="flex justify-center lg:mt-10 md:mt-4 sm:mt-4 mt-4 flex-wrap gap-x-8 lg:gap-x-24 md:gap-x-8 sm:gap-x-8 gap-y-4 lg:gap-y-0 sm:gap-y-4 md:gap-y-4">
                    <div>
                        <img alt="Images" src={image12}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image13}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image14}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image15}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image16}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image17}></img>
                    </div>

                </div>

                <div
                    className="flex justify-center mt-10 flex-wrap gap-x-0 lg:gap-x-24 md:gap-x-0 sm:gap-x-0 gap-y-4 lg:gap-y-0 sm:gap-y-4 md:gap-y-4">
                    <div>
                        <img alt="Images" src={image18}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image19}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image20}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image21}></img>
                    </div>

                    <div>
                        <img alt="Images" src={image22}></img>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default StayAheadWithTechnology;