import logo1 from '../../Images/logo1.png'
import logo2 from '../../Images/logo2.png'
import logo3 from '../../Images/logo3.png'
import logo4 from '../../Images/logo4.png'
import logo5 from '../../Images/logo5.png'

import logo6 from '../../Images/logo6.png'
import logo7 from '../../Images/logo7.png'
import logo8 from '../../Images/logo8.png'
import logo9 from '../../Images/logo9.png'
import logo10 from '../../Images/logo10.png'
import logo11 from '../../Images/logo11.png'

import "./marquee.css"

function StrategicPartners() {
    return (
        <>
            <div className="flex flex-col gap-y-2 justify-center items-center lg:pt-20 md:pt-10 sm:pt-10 pt-10">
                <p className="text-4xl text-[#132B3D] font-bold text-center">Strategic Partners!</p>
                <p className="text-[#808080] arial text-center lg:w-2/5 md:w-full sm:w-full w-full">Together, creating tech that changes the world.
                </p>
            </div>

            <section className='enable-animation my-16'>
                <div className="marquee marquee--hover-pause">
                    <ul className="marquee__content">
                        <img src={logo1} className="inline-block m-3" alt="Logo 1" />
                        <img src={logo2} className="inline-block m-3" alt="Logo 2" />
                        <img src={logo3} className="inline-block m-3" alt="Logo 3" />
                        <img src={logo4} className="inline-block m-3" alt="Logo 4" />
                        <img src={logo5} className="inline-block m-3" alt="Logo 5" />
                        <img src={logo3} className="inline-block m-3" alt="Logo 3" />
                    </ul>

                    <ul aria-hidden="true" className="marquee__content">
                        <img src={logo1} className="inline-block m-3" alt="Logo 1" />
                        <img src={logo2} className="inline-block m-3" alt="Logo 2" />
                        <img src={logo3} className="inline-block m-3" alt="Logo 3" />
                        <img src={logo4} className="inline-block m-3" alt="Logo 4" />
                        <img src={logo5} className="inline-block m-3" alt="Logo 5" />
                        <img src={logo3} className="inline-block m-3" alt="Logo 3" />
                    </ul>
                </div>
            </section>

            <section className='enable-animation mt-3 mb-20'>
                <div className="marquee marquee--reverse marquee--hover-pause">
                    <ul className="marquee__content">
                        <li><img src={logo6} className="inline-block m-3" alt="Logo 1" /></li>
                        <li><img src={logo7} className="inline-block m-3" alt="Logo 2" /></li>
                        <li><img src={logo8} className="inline-block m-3" alt="Logo 3" /></li>
                        <li><img src={logo9} className="inline-block m-3" alt="Logo 4" /></li>
                        <li><img src={logo10} className="inline-block m-3" alt="Logo 5" /></li>
                        <li><img src={logo11} className="inline-block m-3" alt="Logo 6" /></li>
                    </ul>

                    <ul aria-hidden="true" className="marquee__content">
                        <li><img src={logo6} className="inline-block m-3" alt="Logo 1" /></li>
                        <li><img src={logo7} className="inline-block m-3" alt="Logo 2" /></li>
                        <li><img src={logo8} className="inline-block m-3" alt="Logo 3" /></li>
                        <li><img src={logo9} className="inline-block m-3" alt="Logo 4" /></li>
                        <li><img src={logo10} className="inline-block m-3" alt="Logo 5" /></li>
                        <li><img src={logo11} className="inline-block m-3" alt="Logo 6" /></li>
                    </ul>
                </div>
            </section>

        </>
    );
}

export default StrategicPartners;