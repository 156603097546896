import sharjeel from '../../Images/image 35555.png'
import tanveer from '../../Images/tanveer2.png'
import sharjeelImage from '../../Images/sharjeel.png'
import tanveerImage from '../../Images/tanveeeeer.png'
import LinkedinImage from '../../Images/Linkedin.png'
import '../../App.css'
import React from 'react'

function MeetOurFounder() {
    const ref = React.useRef<HTMLDivElement>(null);
    const secondRef = React.useRef<HTMLDivElement>(null);

    const handleOnHover = () => {
        if (ref.current?.classList.contains('animation-remove')) {
            ref.current.classList.remove('animation-remove')
            ref.current.classList.add('animation')
            ref.current.classList.add('mt-24')
        }
    }

    const MouseLeaveHandler = (e) => {
        if (ref.current?.classList.contains('animation')) {
            ref.current?.classList.remove('animation')
            ref.current?.classList.add('animation-remove')
        }
    }

    const handleOnHoverSecond = () => {
        if (secondRef.current?.classList.contains('animation-remove')) {
            secondRef.current.classList.remove('animation-remove')
            secondRef.current.classList.add('animation')
            secondRef.current.classList.add('mt-24')
        }
    }

    const onMouseLeaveSecond = () => {
        if (secondRef.current?.classList.contains('animation')) {
            secondRef.current.classList.remove('animation')
            secondRef.current.classList.add('animation-remove')
        }
    }

    return (
        <div className="flex justify-center items-center lg:px-16 bg-[#FAFAFA] md:px-8 sm:px-8 px-8">
            <div className="flex flex-col gap-y-5 justify-center items-center lg:my-20 md:my-6 sm:my-6 my-6">
                <p className="text-4xl text-[#132B3D] font-bold text-center">Meet Our Founders!</p>
                <div
                    className="lg:w-[1000px] md:w-full sm:w-full w-full flex md:flex-col sm:flex-col flex-col lg:flex-row justify-center items-center lg:mt-8 sm:mt-3 mt-3 md:mt-3 gap-y-12 gap-x-9 ">
                    <div className="flex flex-col lg:justify-end lg:items-end md:justify-center md:items-center sm:justify-center sm:items-center justify-center items-center lg:w-[45%] w-full sm:w-full md:w-full gap-y-4"
                        onMouseOver={handleOnHover}>
                        <img alt="Images" src={sharjeel} className="h-auto w-48"></img>
                        <div className="w-48 flex flex-col justify-center items-center">
                            <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
                                Sharjeel Sajid</h2>
                            <p
                                className="text-[#132B3D] text-center arial font-normal lg:w-72 w-36 sm:w-36 md:w-36 text-xs">
                                Founder & Chief Architect</p>
                        </div>
                    </div>

                    <div className="flex flex-col lg:justify-start lg:items-start md:justify-center md:items-center sm:justify-center sm:items-center justify-center items-center lg:w-[45%] w-full sm:w-full md:w-full gap-y-4 meet-founder"
                        id="Tanveer" onMouseOver={handleOnHoverSecond}>
                        <img alt="Images" src={tanveer} className="h-auto w-48" ></img>
                        <div className="w-48 flex flex-col justify-center items-center">
                            <h2 className="text-[#132B3D] font-bold text-center lg:text-xl text-sm sm:text-sm md:text-sm">
                                Dr. Tanveer Q.</h2>
                            <p className="text-[#132B3D] text-center arial lg:w-72 w-36 sm:w-36 md:w-36  text-xs ">
                               Co-Founder & CEO
                            </p>
                        </div>
                    </div>
                </div>

                <div ref={ref} className="flex flex-col gap-y-5 bg-white p-5 pb-9 lg:h-64 md:h-[35rem] sm:h-[35rem] h-[35rem] absolute animation-remove rounded-3xl lg:w-[55%] md:w-full sm:w-full w-full meet-founder-detail"
                    id="SharjeelBox" onMouseLeave={MouseLeaveHandler}>
                    <div className="w-full flex lg:flex-row md:flex-col sm:flex-col flex-col gap-x-5 items-start justify-between md:gap-y-4 sm:gap-y-4 lg:gap-y-0 gap-y-4">
                        <img alt="Images" src={sharjeelImage}></img>
                        <div className="w-[70%]">
                            <p className="font-bold text-xl text-[#132B3D]">Sharjeel T Sajid</p>
                            <p className="arial text-[#132B3D] text-xs">Founder & Chief Architect</p>
                            <p className="arial text-[#132B3D] lg:mt-6 md:mt-4 sm:mt-4 mt-4 font-bold text-xs">
                                “Strategist with Engineering
                                Heart,
                                Mind,
                                and Soul”</p>
                        </div>
                        <a href="https://www.linkedin.com/in/sharjeelsajid/" target="_blank">
                            <img alt="Images" src={LinkedinImage} className="h-auto w-auto"></img>
                        </a>
                    </div>

                    <div>
                        <p className="arial text-[#808080] lg:text-sm md:text-base sm:text-base text-base">With over 20 years of experience in leading global
                            cross-functional teams in Fortune 500
                            companies, Sharjeel is an expert in conceptualizing and delivering seamless business &
                            technology solutions while driving efficiency, profitability, and business agility
                            through
                            leadership in people, processes, and technology.</p>
                    </div>
                </div>

                <div ref={secondRef} className="flex flex-col gap-y-5 bg-white p-5 pb-9 lg:h-64 md:h-[35rem] sm:h-[35rem] h-[35rem] absolute animation-remove rounded-3xl lg:w-[55%] md:w-full sm:w-full w-full meet-founder-detail"
                    id="TanveerBox" onMouseLeave={onMouseLeaveSecond}>
                    <div
                        className="flex lg:flex-row md:flex-col sm:flex-col flex-col gap-x-5 items-start justify-between md:gap-y-4 sm:gap-y-4 lg:gap-y-0 gap-y-4">
                        <img alt="Images" src={tanveerImage}></img>
                        <div className="w-[70%]">
                            <p className="font-bold text-xl text-[#132B3D]">Dr. Tanveer Qureshi</p>
                            <p className="arial text-[#132B3D] text-xs">Co-Founder & CEO</p>
                            <p className="arial text-[#132B3D] lg:mt-6 md:mt-4 sm:mt-4 mt-4 font-bold text-xs">
                                “Principle Dentist & Boss Lady”</p>
                        </div>
                        <a href="https://www.linkedin.com/in/drdg/" target="_blank">
                            <img alt="Images" src={LinkedinImage} className="h-auto w-auto"></img>
                        </a>
                    </div>
                    <div>
                        <p className="arial text-[#808080] lg:text-sm md:text-base sm:text-base text-base">A mother, a homemaker & a Boss Lady, Dr. Tanveer
                            wears
                            many hats each day. For over 16 years in dentistry with certifications from Northern
                            Virginia Community College and Ohio State University, Dr. Tanveer has been in private
                            practice at Sajid Dental Clinic, different hospitals, and community clinics. She
                            celebrates
                            excellent relationship with her patients & team alike.</p>
                    </div>
                </div>

            </div>

        </div>

    );
}

export default MeetOurFounder;