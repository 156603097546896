import '../../App.css'
import { useEffect, useState } from 'react';
import TopButton from '../../Images/totop.png'
import { Link } from "react-router-dom";

function Footer() {
    const [ScroloButton, setScrollButton] = useState(false)

    useEffect(() => {
        const onScroll: EventListener = (event: Event) => {
            if (window.scrollY > 100) {
                setScrollButton(true);
            } else {
                setScrollButton(false)
            }
        };

        const win: Window = window;
        win.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, []);

    const handleClick = (e) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className="mt-auto flex flex-col lg:flex-row sm:flex-col md:flex-col justify-between items-center px-5 lg:px-12 sm:px-5 md:px-5 text-[#132B3D] border-t border-[#C4C4C4] py-2 lg:gap-y-0 gap-y-3 sm:gap-y-3 md:gap-y-3" >
            <p>Engineering Heart ❤️, Mind 🧠 & Soul ✨</p>
            <div className="flex items-center gap-x-3 lg:gap-x-10 sm:gap-x-3 md:gap-x-3 lg:gap-y-0 gap-y-3 sm:gap-y-3 md:gap-y-3">

                {/* <a target="_blank" href="https://lathransoft.com/uplaods/Privay-Policy.pdf">Privacy
                    Policy</a> */}
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
                <div className="flex gap-x-2">
                    {/* Faceboook Logo */}
                    <a href="https://www.facebook.com/latthransoft" target="_blank" className='group' >
                        <svg className="hover:scale-110 transform transition duration-500 ease-in-out" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="36" height="36" rx="18" fill="#F0F0F0" className="group-hover:fill-blue-600" />
                            <path className="group-hover:fill-white" d="M23.0002 9.6665H20.5002C19.3951 9.6665 18.3353 10.1055 17.5539 10.8869C16.7725 11.6683 16.3335 12.7281 16.3335 13.8332V16.3332H13.8335V19.6665H16.3335V26.3332H19.6668V19.6665H22.1668L23.0002 16.3332H19.6668V13.8332C19.6668 13.6122 19.7546 13.4002 19.9109 13.2439C20.0672 13.0876 20.2791 12.9998 20.5002 12.9998H23.0002V9.6665Z" fill="#132B3D" />
                        </svg>
                    </a>
                    {/* Instagram Logo */}
                    <a href="https://www.instagram.com/lathransoft/" target="_blank" className='group'>
                        <div className="group bg-[#F0F0F0] rounded-full w-[36px] h-[36px] flex justify-center items-center hover:bg-gradient-to-r from-[#d62976] to-[#e98f4b] hover:scale-110 transform transition duration-500 ease-in-out">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="#F0F0F0" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1357_1097)">
                                    <path className="group-hover:stroke-white group-hover:fill-[#d62976]" d="M14.1665 1.6665H5.83317C3.53198 1.6665 1.6665 3.53198 1.6665 5.83317V14.1665C1.6665 16.4677 3.53198 18.3332 5.83317 18.3332H14.1665C16.4677 18.3332 18.3332 16.4677 18.3332 14.1665V5.83317C18.3332 3.53198 16.4677 1.6665 14.1665 1.6665Z" stroke="#132B3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path className="group-hover:stroke-white group-hover:fill-[#d62976]" d="M13.3333 9.47476C13.4361 10.1683 13.3176 10.8766 12.9947 11.4989C12.6718 12.1213 12.1609 12.6259 11.5346 12.9412C10.9083 13.2564 10.1986 13.3661 9.50641 13.2547C8.81419 13.1433 8.17472 12.8165 7.67895 12.3207C7.18318 11.825 6.85636 11.1855 6.74497 10.4933C6.63359 9.80106 6.74331 9.09134 7.05852 8.46507C7.37374 7.83881 7.87841 7.32788 8.50074 7.00496C9.12307 6.68205 9.83138 6.56359 10.5249 6.66643C11.2324 6.77133 11.8873 7.10098 12.393 7.60669C12.8987 8.11239 13.2283 8.76733 13.3333 9.47476Z" stroke="#132B3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path className="group-hover:stroke-white group-hover:fill-[#d62976]" d="M14.5835 5.4165H14.5935" stroke="#132B3D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1357_1097">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </a>

                    {/* Linked Logo */}
                    <a href="https://www.linkedin.com/company/lathransoft-llc/" target="_blank" className='group'>
                        <svg className="hover:scale-110 transform transition duration-500 ease-in-out" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect className="group-hover:fill-blue-600" width="36" height="36" rx="18" fill="#F0F0F0" />
                            <path className="group-hover:fill-white" d="M21.3335 14.6665C22.6596 14.6665 23.9313 15.1933 24.869 16.131C25.8067 17.0687 26.3335 18.3404 26.3335 19.6665V25.4998H23.0002V19.6665C23.0002 19.2245 22.8246 18.8006 22.512 18.488C22.1994 18.1754 21.7755 17.9998 21.3335 17.9998C20.8915 17.9998 20.4675 18.1754 20.155 18.488C19.8424 18.8006 19.6668 19.2245 19.6668 19.6665V25.4998H16.3335V19.6665C16.3335 18.3404 16.8603 17.0687 17.798 16.131C18.7356 15.1933 20.0074 14.6665 21.3335 14.6665Z" fill="#132B3D" />
                            <path className="group-hover:fill-white" d="M12.9998 15.5H9.6665V25.5H12.9998V15.5Z" fill="#132B3D" />
                            <path className="group-hover:fill-white" d="M11.3332 12.9998C12.2536 12.9998 12.9998 12.2536 12.9998 11.3332C12.9998 10.4127 12.2536 9.6665 11.3332 9.6665C10.4127 9.6665 9.6665 10.4127 9.6665 11.3332C9.6665 12.2536 10.4127 12.9998 11.3332 12.9998Z" fill="#132B3D" />
                        </svg>
                    </a>
                </div>

                <a id="button" className={`hover:cursor-pointer fixed right-12 bottom-16 ${ScroloButton === true ? "visible" : "invisible"}`} onClick={handleClick}>
                    <img src={TopButton} alt="TopButton"></img>
                </a>
            </div>
        </div >
    );
}

export default Footer;