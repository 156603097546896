import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import HowWeDoItSection from "../Components/PODPage/HowWeDOItSection";
import SimplifyComplexity from "../Components/PODPage/SimplifyComplexity";
import LathranSecretFormula from "../Components/PODPage/LathranSecretFormula";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const paragraphText: string = "Portable On-Demand!";

function POD() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <HeaderSection paragraphText={paragraphText} />
      <LathranSecretFormula />
      <HowWeDoItSection />
      <SimplifyComplexity />
      <Footer />
    </div>
  );
}

export default POD;
