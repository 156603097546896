import Header from "../Components/GlobalComponent/Header";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import Footer from "../Components/GlobalComponent/Footer";
import Team from "../Components/WhoWeAre/Team";
import ProudNumbers from "../Components/GlobalComponent/ProudNumbers";
import MeetOurFounder from "../Components/WhoWeAre/MeetOurFounders";
import "../App.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const paragraphText: string = "What Makes Us?";

function WhoWeAre() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);
  
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <HeaderSection paragraphText={paragraphText} />
      <Team />
      <MeetOurFounder />
      <ProudNumbers />
      <Footer />
    </div>
  );
}

export default WhoWeAre;
