import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderSection from "../Components/GlobalComponent/HeaderSection";
import PrivacyPolicyText from "../Components/PrivacyPolicy/PrivacyPolicyText";

function PrivacyPolicy() {
    const paragraphText: string = "Privacy Policy";

    return (
        <div className="h-screen flex flex-col">
            <Header />
            <HeaderSection paragraphText={paragraphText} />
            <PrivacyPolicyText />
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
