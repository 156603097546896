import ApplyOnlineImage from '../../Images/Group 501.png'
import ReviewImage from '../../Images/Group 500.png'
import InterviewsImage from '../../Images/Group 500.png'
import DecisionImage from '../../Images/Group 502.png'
import OnBoardingImage from '../../Images/Group 503.png'
import HireImage from '../../Images/aff 1.png'

function OurHiringProcess() {
    return (
        <div
            className="flex flex-col lg:px-32 md:px-10 sm:px-10 px-10 justify-center items-center lg:gap-y-16 md:gap-y-8 sm:gap-y-8 gap-y-8 my-16">
            <p className="sm:text-3xl text-3xl md:text-3xl lg:text-4xl text-[#132B3D] font-bold text-center">Our Hiring Process!</p>
            <div className="flex lg:flex-row md:flex-col sm:flex-col flex-col gap-x-24 lg:gap-y-0 md:gap-y-6 sm:gap-y-6 gap-y-6">
                <div className="flex justify-center items-center w-full lg:w-1/2 md:w-full sm:w-full">
                    <img src={HireImage} className="lg:w-[400px] w-60 md:w-60 sm:w-60 h-auto" alt="HireImage"></img>
                </div>
                <div className=" flex flex-col gap-y-6 w-full lg:w-1/2 md:w-full sm:w-full">
                    <div className="flex gap-x-3 w-full">
                        <img src={ApplyOnlineImage} className="w-6 h-6 mt-1" alt="ApplyOnlineImage"></img>
                        <div>
                            <p className="font-bold text-[#AB322F] text-lg">Apply Online</p>
                            <p className="text-[#132B3D] arial text-sm font-normal">We recommend you focus on those jobs
                                that excite you.
                                Submitting dozens of applications
                                will
                                not help. Focus on quality, not quantity!</p>
                        </div>
                    </div>

                    <div className="flex gap-x-3 w-full">
                        <img src={ReviewImage} className="w-6 h-6 mt-1" alt="ReviewImage"></img>
                        <div>
                            <p className="font-bold text-[#AB322F] text-lg">Review</p>
                            <p className="text-[#132B3D] arial text-sm font-normal">Our expert recruiters review your
                                application. They will screen your resume and find the best fit for you.</p>
                        </div>
                    </div>

                    <div className="flex gap-x-3 w-full">
                        <img src={InterviewsImage} className="w-6 h-6 mt-1" alt="InterviewsImage"></img>
                        <div>
                            <p className="font-bold text-[#AB322F] text-lg">Interviews</p>
                            <p className="text-[#132B3D] arial text-sm font-normal">If our People management team believes
                                you might be a match for a job, you will enter our hiring process. Hurrah! You passed
                                the first step.</p>
                        </div>
                    </div>

                    <div className="flex gap-x-3 w-full">
                        <img src={DecisionImage} className="w-6 h-6 mt-1" alt="DecisionImage"></img>
                        <div>
                            <p className="font-bold text-[#AB322F] text-lg">Decision</p>
                            <p className="text-[#132B3D] arial text-sm font-normal">Once the interview cycles end, we will
                                bring everything together from your application and interviews and review it. If you
                                pass, you will receive a "Welcome Onboard" message.</p>
                        </div>
                    </div>

                    <div className="flex gap-x-3 w-full">
                        <img src={OnBoardingImage} className="w-6 h-6 mt-1" alt="OnBoardingImage"></img>
                        <div>
                            <p className="font-bold text-[#AB322F] text-lg">Onboarding</p>
                            <p className="text-[#132B3D] arial text-sm font-normal">Once you’ve received the “Welcome
                                Aboard” message, our onboarding experience will polish and set you up for your
                                professional journey with LathranSoft.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurHiringProcess;